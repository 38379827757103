import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const SocialIcons = () => {
    return (
        <>
            <a href="https://www.facebook.com/deadparrotsociety/" class="fa fa-facebook" target="_blank" rel="noreferrer" ><FacebookIcon /></a>
            <a href="https://www.instagram.com/deadparrotrocks/" class="fa fa-instagram" target="_blank" rel="noreferrer" ><InstagramIcon /></a>
            <a href="https://www.twitter.com/deadparrotrocks/" class="fa fa-twitter" target="_blank" rel="noreferrer" ><TwitterIcon /></a>
        </>
    )
}

export default SocialIcons;