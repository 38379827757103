import { Routes } from 'react-router-dom';
import {Route} from 'react-router-dom';
import SocialIcons from './SocialIcons';
import {Herman} from './herman';
import './App.css';


function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="herman" element={ <Herman/> } />
      </Routes>
      <div id="fb-root"></div>
      <div class="container">
        <div class="starter-template row">
          <h1 class="col-12 center">Dead Parrot Society</h1>
          <img class="col-12" src="./images/bandbilde_2.jpg" height="100%" alt="Portrettbilde av alle medlemmene i Dead Parrot Society" />
        </div>
        <div class="starter-template row">
          <div class="col-12">
            <h2>Bio</h2>
            <p>Dead Parrot Society are trying to fight everyday boredom with easy puns and slow punk-rock. A musical blend inspired by hardcore punk, blues, garage rock and classic punk rock. March 2019 they released their second EP, Kitchen Tapes Vol. 2.</p>
            <p>The band started in summer of 2016 with two guitarists. After 6 months they realised that a band with no drummer ain't no band. So they recruited a drummer. Soon after Pål the drummer joined, they started to record demos in a basement and anywhere else they could hook up a recorder without being chased.  The demos became the Kitchen Tapes Vol. 1 + 2. Two years later,  they still record their songs in a basement but they (allegedly) got better playing, recording and producing. </p>
            <p>Late 2018 Herman the bassplayer joined, and it was much rejoicing.</p>
            <p>We will be doing this as long as it is fun.</p>
            <div>
              <iframe title="Spotiyplaylist" src="https://open.spotify.com/embed/artist/4unjkHmgDfB7Kq9tX6owaR" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe><br />
            </div>
            <br />
            <div>
                <SocialIcons />
            </div>
            <br />
            <div>
              <a href="mailto:deadparrotrocks@gmail.com">deadparrotrocks@gmail.com</a><br />
            </div>
              <br />
              <h2>Dead Parrot Society is:</h2>
              <img width="100%" src="./images/bandbilde_3.jpg" alt="Dead Parrot Society foran et trehus, Kristoffer peker mot venstre og går ut av bildet mens de andre fniser. De står i denne rekkefølgen: Kristoffer, Håvard, Pål og Herman." />
              <ul style={{ listStyle:'none' }}>
                <li>Håvard Småvik (vox and guitar)</li>
                <li>Kristoffer Johansen (guitar)</li>
                <li>Pål Plahte (drums)</li>
                <li>Herman Strand Lilleng (bass)</li>
              </ul>
            </div>
          </div>
          <div class="starter-template row">
            <h2>Releases</h2>
            <ul>
              <li>Kitchen Tapes Vol. 1, EP (7. september 2018)</li>
              <li>Kitchen Tapes Vol. 2, EP (8. march 2019)</li>
              <li>Cant Fix Me, single (1. november 2019)</li>
              <li>Midnight Runner, single (14. february 2020)</li>
              <li>Daytime Dirtywork, single (12. june 2020)</li>
              <li>Left Hand, single (21. august 2019)</li>              
            </ul>
          </div>
          <div class="starter-template row">
            <div class="col-12">
              <iframe title="Soundcloud playlist" width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/721536570&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            </div>
          </div>
          <div class="starter-template row">
            <div class="col-12">
              <h2>Live:</h2>
              <h3>Nothing planned, please feel free to book us - <a href="mailto: deadparrotrocks@gmail.com">deadparrotrocks@gmail.com</a></h3>
              <h3>Earlier shows</h3>
              <ul>
                <li><a href="https://www.facebook.com/events/392931287918642/" target="_blank" rel="noreferrer" >2019 - 28. march - Cafe Mir. Release Part for Kitchen Tapes Vol. 2, with the Deadly Sins (Oslo, NO)</a></li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  );
}

export default App;
